@font-face {
    font-family: 'AdihausDIN Cn';
    src: url('../fonts/adihaus/AdihausDIN-CnBold.eot');
    src: url('../fonts/adihaus/AdihausDIN-CnBold.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-CnBold.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-CnBold.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-CnBold.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-CnBold.svg#AdihausDIN-CnBold')
            format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('../fonts/adihaus/AdihausDIN-BoldItalic.eot');
    src: url('../fonts/adihaus/AdihausDIN-BoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-BoldItalic.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-BoldItalic.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-BoldItalic.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-BoldItalic.svg#AdihausDIN-BoldItalic')
            format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'AdihausDIN Cn';
    src: url('../fonts/adihaus/AdihausDIN-CnItalic.eot');
    src: url('../fonts/adihaus/AdihausDIN-CnItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-CnItalic.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-CnItalic.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-CnItalic.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-CnItalic.svg#AdihausDIN-CnItalic')
            format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AdihausDIN Cn';
    src: url('../fonts/adihaus/AdihausDIN-Cn.eot');
    src: url('../fonts/adihaus/AdihausDIN-Cn.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-Cn.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-Cn.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-Cn.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-Cn.svg#AdihausDIN-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('../fonts/adihaus/AdihausDIN-Medium.eot');
    src: url('../fonts/adihaus/AdihausDIN-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-Medium.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-Medium.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-Medium.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-Medium.svg#AdihausDIN-Medium')
            format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('../fonts/adihaus/AdihausDIN-Regular.eot');
    src: url('../fonts/adihaus/AdihausDIN-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-Regular.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-Regular.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-Regular.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-Regular.svg#AdihausDIN-Regular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('../fonts/adihaus/AdihausDIN-Italic.eot');
    src: url('../fonts/adihaus/AdihausDIN-Italic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-Italic.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-Italic.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-Italic.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-Italic.svg#AdihausDIN-Italic')
            format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AdihausDIN Cn';
    src: url('../fonts/adihaus/AdihausDIN-CnMediumItalic.eot');
    src: url('../fonts/adihaus/AdihausDIN-CnMediumItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-CnMediumItalic.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-CnMediumItalic.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-CnMediumItalic.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-CnMediumItalic.svg#AdihausDIN-CnMediumItalic')
            format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('../fonts/adihaus/AdihausDIN-Bold.eot');
    src: url('../fonts/adihaus/AdihausDIN-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-Bold.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-Bold.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-Bold.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-Bold.svg#AdihausDIN-Bold')
            format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'AdihausDIN Cn';
    src: url('../fonts/adihaus/AdihausDIN-CnMedium.eot');
    src: url('../fonts/adihaus/AdihausDIN-CnMedium.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-CnMedium.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-CnMedium.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-CnMedium.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-CnMedium.svg#AdihausDIN-CnMedium')
            format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AdihausDIN Cn';
    src: url('../fonts/adihaus/AdihausDIN-CnBoldItalic.eot');
    src: url('../fonts/adihaus/AdihausDIN-CnBoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-CnBoldItalic.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-CnBoldItalic.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-CnBoldItalic.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-CnBoldItalic.svg#AdihausDIN-CnBoldItalic')
            format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('../fonts/adihaus/AdihausDIN-MediumItalic.eot');
    src: url('../fonts/adihaus/AdihausDIN-MediumItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adihaus/AdihausDIN-MediumItalic.woff2') format('woff2'),
        url('../fonts/adihaus/AdihausDIN-MediumItalic.woff') format('woff'),
        url('../fonts/adihaus/AdihausDIN-MediumItalic.ttf') format('truetype'),
        url('../fonts/adihaus/AdihausDIN-MediumItalic.svg#AdihausDIN-MediumItalic')
            format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-BoldItalicWeb.eot');
    src: url('../fonts/adineue/adineuePRO-BoldItalicWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-BoldItalicWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-BoldItalicWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-BoldItalicWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-BoldItalicWeb.svg#../fonts/adineue/adineuePRO-BoldItalicWeb')
            format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-BlackWeb.eot');
    src: url('../fonts/adineue/adineuePRO-BlackWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-BlackWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-BlackWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-BlackWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-BlackWeb.svg#../fonts/adineue/adineuePRO-BlackWeb')
            format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-BoldWeb.eot');
    src: url('../fonts/adineue/adineuePRO-BoldWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-BoldWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-BoldWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-BoldWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-BoldWeb.svg#../fonts/adineue/adineuePRO-BoldWeb')
            format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-RegularWeb.eot');
    src: url('../fonts/adineue/adineuePRO-RegularWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-RegularWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-RegularWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-RegularWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-RegularWeb.svg#../fonts/adineue/adineuePRO-RegularWeb')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-LightItalicWeb.eot');
    src: url('../fonts/adineue/adineuePRO-LightItalicWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-LightItalicWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-LightItalicWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-LightItalicWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-LightItalicWeb.svg#../fonts/adineue/adineuePRO-LightItalicWeb')
            format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-BlackItalicWeb.eot');
    src: url('../fonts/adineue/adineuePRO-BlackItalicWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-BlackItalicWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-BlackItalicWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-BlackItalicWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-BlackItalicWeb.svg#../fonts/adineue/adineuePRO-BlackItalicWeb')
            format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-LightWeb.eot');
    src: url('../fonts/adineue/adineuePRO-LightWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-LightWeb.woff2') format('woff2'),
        url('../fonts/adineue/adineuePRO-LightWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-LightWeb.ttf') format('truetype'),
        url('../fonts/adineue/adineuePRO-LightWeb.svg#../fonts/adineue/adineuePRO-LightWeb')
            format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'adineue';
    src: url('../fonts/adineue/adineuePRO-RegularItalicWeb.eot');
    src: url('../fonts/adineue/adineuePRO-RegularItalicWeb.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePRO-RegularItalicWeb.woff2')
            format('woff2'),
        url('../fonts/adineue/adineuePRO-RegularItalicWeb.woff') format('woff'),
        url('../fonts/adineue/adineuePRO-RegularItalicWeb.ttf')
            format('truetype'),
        url('../fonts/adineue/adineuePRO-RegularItalicWeb.svg#../fonts/adineue/adineuePRO-RegularItalicWeb')
            format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'adineuePROCond';
    src: url('../fonts/adineue/adineuePROCond-Regular.eot');
    src: local('adineue PRO Cond Regular'), local('adineuePROCond-Regular'),
        url('../fonts/adineue/adineuePROCond-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePROCond-Regular.woff2') format('woff2'),
        url('../fonts/adineue/adineuePROCond-Regular.woff') format('woff'),
        url('../fonts/adineue/adineuePROCond-Regular.ttf') format('truetype'),
        url('../fonts/adineue/adineuePROCond-Regular.svg#adineuePROCond-Regular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adineuePROCond';
    src: url('../fonts/adineue/adineuePROCond-Black.eot');
    src: local('adineue PRO Cond Black'), local('adineuePROCond-Black'),
        url('../fonts/adineue/adineuePROCond-Black.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePROCond-Black.woff2') format('woff2'),
        url('../fonts/adineue/adineuePROCond-Black.woff') format('woff'),
        url('../fonts/adineue/adineuePROCond-Black.ttf') format('truetype'),
        url('../fonts/adineue/adineuePROCond-Black.svg#adineuePROCond-Black')
            format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'adineuePROCond';
    src: url('../fonts/adineue/adineuePROCond-Bold.eot');
    src: local('adineue PRO Cond Bold'), local('adineuePROCond-Bold'),
        url('../fonts/adineue/adineuePROCond-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePROCond-Bold.woff2') format('woff2'),
        url('../fonts/adineue/adineuePROCond-Bold.woff') format('woff'),
        url('../fonts/adineue/adineuePROCond-Bold.ttf') format('truetype'),
        url('../fonts/adineue/adineuePROCond-Bold.svg#adineuePROCond-Bold')
            format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'adineuePROCond';
    src: url('../fonts/adineue/adineuePROCond-Light.eot');
    src: local('adineue PRO Cond Light'), local('adineuePROCond-Light'),
        url('../fonts/adineue/adineuePROCond-Light.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/adineue/adineuePROCond-Light.woff2') format('woff2'),
        url('../fonts/adineue/adineuePROCond-Light.woff') format('woff'),
        url('../fonts/adineue/adineuePROCond-Light.ttf') format('truetype'),
        url('../fonts/adineue/adineuePROCond-Light.svg#adineuePROCond-Light')
            format('svg');
    font-weight: 300;
    font-style: normal;
}
