@import-normalize;
@import 'fonts.css';

*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
